*,
html,
body,
p {
    margin: 0;
    padding: 0;
}
a,
a:active,
a:focus,
a:hover {
    color: inherit;
}
.blue {
    color: blue;
}
html,
body {
    height: 100%;
}
#root {
    height: 100%;
}
.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.content {
    flex: 1 0 auto;
}
.footer {
    flex: 0 0 auto;
}
.row-hover {
    cursor: pointer;
}
#root .row-hover.row-selected,
#root .row-hover.row-selected:hover {
    background: #3f8aca;
    color: #fff;
}
.people-menu-item {
    border: 1px solid #eaeaea;
    width: 100px;
    height: 100px;
    float: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 1px;
    margin-bottom: 10px;
    flex-direction: column;
    padding: 23px 2px;
    line-height: 17px;
}
.people-menu-item i {
    margin-bottom: 4px;
}

.active .people-menu-item {
    border-color: #40a9ff;
    border-width: 3px;
    padding: 21px 0px;
}
#root .ag-cell:not(.ag-cell-focus) {
    border: 1px solid #f5f5f5;
}
#root .compact-table th,
#root .compact-table td {
    padding: 1px;
    font-size: 12px;
}
#root .compact-table th {
    text-align: center;
}
#root .ag-header-cell-label,
#root .ag-header-group-cell-label {
    justify-content: center;
}
/* reduce ag-grid default header line-height and padding */
#root .ag-theme-balham .ag-header-cell {
    line-height: normal;
    padding: 8px;
}

/* make header separator height proportional to header height */
#root .ag-theme-balham .ag-header-cell:after,
#root .ag-theme-balham .ag-header-group-cell:after {
    height: 50%;
}

/* set icons height to their real absolute value to ensure proper vertical alignment */
#root .ag-theme-balham .ag-header-cell-menu-button .ag-icon-menu,
#root .ag-theme-balham .ag-header-cell-label .ag-header-icon {
    height: 16px;
}

/* make header text wrap, without breaking words and without ellipsis */
#root .ag-theme-balham .ag-header-cell-label .ag-header-cell-text {
    height: auto;
    overflow: visible;
    overflow-wrap: normal;
    text-overflow: clip;
    white-space: normal;
}
.right-up {
    top: 0;
    background: url('./images/right_up.png');
    height: 299px;
}
.right-down {
    bottom: 0;
    background: url('./images/right_down.png');
    height: 281px;
}
.right-down, .right-up {
    position: absolute;
    right: 0;
    width: 120px;
}
.left-down, .left-up {
    position: absolute;
    left: 0;
    width: 120px;
}
.left-up {
    top: 0;
    background: url('./images/left_up.png');
    height: 221px;
}
.left-down {
    bottom: 0;
    background: url('./images/left_down.png');
    height: 211px;
}


.login-logo-svfu {
    background: url('./images/logo_svfu.png');
    width: 100px;
    height: 86px;
}

 @media  (max-width:1000px) {
    .left-up,.left-down,.right-down,.right-up {
        display: none;
    }
}

@media  (max-height:800px) {
    .left-up,.left-down,.right-down,.right-up,.login-logo-svfu {
        display: none;
    }
}
@media  (max-height:600px) {
    .header {
        display: none;
    }
}


table {
    font-family: "Lucida Sans Unicode", "Lucida Grande", Sans-Serif;
    font-size: 12px;
    border-collapse: collapse;
    text-align: center;
    }
    /*
    th, td:first-child {
    background: #AFCDE7;
    color: white;
    padding: 10px 14px;
    }*/
    th, td {
    border-style: solid;
    border-width: 0 1px 1px 0;
    border-color: white;
    }
    
    td {
    background: #D8E6F3;
    }
    th:first-child, td:first-child {
    text-align: left;
    }

    .resp-container {
        position: relative;
        overflow: hidden;
        padding-top: 100%;
    }
    .resp-iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }